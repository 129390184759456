import React from "react";
import { SEO, MarkdownContent } from "@bluefin/components";
import { Grid, Segment, Embed } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class GiftCertificatesPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={"custom-page-gift-certificates custom-page-grid"}
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"gswtvydscnigpbfb"}
            style={{ padding: 16 }}
            columns={1}
          >
            <Grid.Column
              className={"absbsfhuzhgeefkb"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f98f2025-1e00-4a33-a7ae-2df9cee31c13",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"vkxixjbcgaeguwhw"}
            style={{ padding: 16 }}
            columns={2}
          >
            <Grid.Column className={"mwlhnthsgbqsutpy"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <React.Fragment
                  key={"JSON__36b2f504-0622-43c8-91d4-35247cd828e5"}
                >
                  <div className={"home-hero-video-container"}>
                    <div className={"hero-overlay"} />
                    <Embed
                      url={
                        "https://player.vimeo.com/video/1033187357?background=1"
                      }
                      autoplay={true}
                      defaultActive={true}
                      defaultActive={true}
                      className={"home-hero-video"}
                    />
                    <div className={"hero-content"} />
                  </div>
                </React.Fragment>
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"nkpckcxbanlpcnpa"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "fe6d61c6-a831-4770-814b-2db786fbedc4",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 27833 }) {
      title
      seoTitle
      description
      components {
        files {
          file
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
